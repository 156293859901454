<script>
import DocumentListComponent from "./components/resources/DocumentListComponent.vue";
import AutoriryComponent from "./components/resources/AutoriryComponent.vue";
import NewsComponent from "./components/resources/NewsComponent.vue";
import ITCGridComponent from "./components/resources/ITCGridComponent.vue";
import BoxIconGridComponent from "./components/resources/BoxIconGridComponent.vue";
import MultiSliderComponent from "./components/resources/MultiSliderComponent.vue";
import FrecuentAccessComponent from "./components/resources/FrecuentAccessComponent.vue";
import GridLinkComponent from "./components/resources/GridLinkComponent.vue";
import CardGridComponent from "./components/resources/CardGridComponent.vue";
import ChatBotComponent from "./components/resources/ChatBotComponent.vue";

export default {
  name: "HomeView",
  components: {
    /* eslint-disable */
    DocumentListComponent,
    /* eslint-disable */
    AutoriryComponent,
    /* eslint-disable */
    NewsComponent,
    /* eslint-disable */
    ITCGridComponent,
    /* eslint-disable */
    BoxIconGridComponent,
    /* eslint-disable */
    MultiSliderComponent,
    /* eslint-disable */
    FrecuentAccessComponent,
    /* eslint-disable */
    GridLinkComponent,
    /* eslint-disable */
    CardGridComponent,
    /* eslint-disable */
    ChatBotComponent,
  },
  data() {
    return {
      site: 29,
    };
  },
  mounted() {
    document.title = process.env.VUE_APP_TITLE;
  },
  methods: {
    trLCGA() {
      gtag('event', 'link_click', {
        'event_category': 'Navigation',
        'event_label': 'CiDi Landing a Rentas'
      });
    }
  }
};
</script>

<template>
  <!-- MENU -->
  <div class="d-flex justify-content-center bg-dark pt-2 pb-2">
    <ul class="nav flex-column flex-md-row justify-content-center align-items-center">
      <li class="nav-item">
        <a class="nav-link text-center d-flex align-items-center text-ptn-color-gsj-brand" href="">
          <span class="material-symbols-outlined fac-span mr-2 text-ptn-color-gsj-brand font-weight-bold"
            style="font-size: 1.5em">house</span>Inicio
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link text-center d-flex align-items-center text-ptn-color-gsj-brand" href="?uid=CUMV-que-es">
          <span class="material-symbols-outlined fac-span mr-2 text-ptn-color-gsj-brand font-weight-bold"
            style="font-size: 1.5em">view_list</span>¿Qué es?
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link text-center d-flex align-items-center texto-menu text-ptn-color-gsj-brand"
          href="?uid=CUMV-tutorial">
          <span class="material-symbols-outlined fac-span mr-2 text-ptn-color-gsj-brand font-weight-bold"
            style="font-size: 1.5em">account_box</span>Cómo creo mi cuenta
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link text-center d-flex align-items-center texto-menu text-ptn-color-gsj-brand"
          href="https://mi.sanjuan.gob.ar/login">
          <span class="material-symbols-outlined fac-span mr-2 text-ptn-color-gsj-brand font-weight-bold"
            style="font-size: 1.5em">login</span>Ingresar a mi cuenta
        </a>
      </li>
    </ul>
  </div>
  <!-- FIN MENU -->

  <!-- Moratoria -->
  <section class="bg-white py-0 mt-4">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mx-auto text-center d-none d-md-block">
          <a class="linkDGR" href="https://moratoria.sanjuan.gob.ar/" target="_blank" @click="trLCGA">
            <img class="img-responsive img-fluid" src="img/moratoriadgr.png" 
              title="Moratoria 2024 - Dirección General de Rentas" 
              alt="Moratoria 2024 - Dirección General de Rentas" />
          </a>
        </div>
        <div class="col-lg-12 d-md-none mx-auto text-center">
          <a class="linkDGR" href="https://moratoria.sanjuan.gob.ar/" target="_blank" @click="trLCGA">
            <img class="img-responsive img-fluid" src="img/moratoriadgrmov.png"  
              title="Moratoria 2024 - Dirección General de Rentas" alt="Moratoria 2024 - Dirección General de Rentas" />
          </a>
        </div>
      </div>
    </div>
  </section>
  <!-- Moratoria -->

  <!-- Seccion Información CIDI -->
  <section>
    <div class="container">
      <h2 class="pb-4">¿Por qué ser Ciudadano Digital?</h2>
      <div class="row">
        <div class="col-12 col-sm-4 d-flex text-center justify-content-center align-items-center">
          <img class="img-fluid" src="./assets/img/imagen-porque.svg" />
        </div>

        <div class="col-12 col-sm-8 pt-4 order-first order-sm-last">
          <div class="row">
            <div class="col-12 pb-4">
              <div class="card">
                <div class="card-body rounded d-flex align-items-center">
                  <div>
                    <h5 class="card-title stretched-link link-secondary text-ptn-color-gsj-2">
                      AHORRARÁS TIEMPO Y ACHICARÁS DISTANCIAS
                    </h5>
                    <p class="card-text">
                      Podrás acceder desde cualquier lugar y a través de tu
                      dispositivo móvil o PC con Internet
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 pb-4">
              <div class="card">
                <div class="card-body rounded d-flex align-items-center">
                  <div>
                    <h5 class="card-title stretched-link link-secondary text-ptn-color-gsj-2">
                      EN UNA SOLA PLATAFORMA, MUCHOS DE TUS TRÁMITES
                    </h5>
                    <p class="card-text">
                      Ciudadano Digital te permitirá resolver gran variedad de
                      trámites y acceder a documentación específica. Todo en un
                      mismo sitio.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 pb-4">
              <div class="card">
                <div class="card-body rounded d-flex align-items-center">
                  <div>
                    <h5 class="card-title stretched-link link-secondary text-ptn-color-gsj-2">
                      DATOS PERSONALES EN UN ENTORNO DIGITAL CONFIDENCIAL
                    </h5>
                    <p class="card-text">
                      Ciudadano Digital tiene un alto nivel de seguridad que
                      garantiza la privacidad de tus datos.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 pb-4">
              <div class="card">
                <div class="card-body rounded d-flex align-items-center">
                  <div>
                    <h5 class="card-title stretched-link link-secondary text-ptn-color-gsj-2">
                      VALIDEZ LEGAL
                    </h5>
                    <p class="card-text">
                      Todos tus documentos y trámites realizados a través de
                      Ciudadano Digital son legítimos. Por lo tanto, pueden ser
                      presentados en organismos públicos y privados. Para
                      validar tu información, contarás con un código impreso en
                      el mismo documento que permitirá corroborar la veracidad
                      del mismo.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Fin Seccion Información CIDI -->

  <!-- Seccion TRAMITES CIDI -->
  <section class="bg-light">
    <div class="container">
      <h2 class="pb-2">¿Qué trámites y servicios puedo realizar?</h2>
      <div class="row">
        <div class="col-12 col-sm-4 d-flex text-center justify-content-center align-items-center">
          <img class="img-fluid" src="./assets/img/tramites.svg" alt="Icono de trámites" />
        </div>

        <div class="col-12 col-sm-8 pt-4 order-first order-sm-last">
          <div class="pb-4">
            <div class="card bg-white rounded">
              <div class="card-body d-flex align-items-center">
                <div>
                  <h5 class="card-title stretched-link link-secondary text-ptn-color-gsj-2">
                    AHORRARÁS TIEMPO Y ACHICARÁS DISTANCIAS
                  </h5>
                  <p class="card-text">
                    Actualmente, se encuentran disponibles diversos trámites y
                    servicios. No obstante, el Gobierno de la Provincia de San
                    Juan continúa trabajando para ampliar la lista y,
                    consecuentemente, construir una plataforma cada vez más
                    completa.
                  </p>
                </div>
              </div>
            </div>
            <div class="pt-4 d-flex align-items-center justify-content-center mx-auto">
              <ul class="list-unstyled">
                <li>
                  <p class="fac-a">
                    <span class="material-symbols-outlined fac-span">task_alt</span>
                    Partidas de nacimiento
                  </p>
                </li>
                <li>
                  <p class="fac-a">
                    <span class="material-symbols-outlined fac-span">task_alt</span>
                    Certificado de Activos (para agentes públicos)
                  </p>
                </li>
                <li>
                  <p class="fac-a">
                    <span class="material-symbols-outlined fac-span">task_alt</span>
                    Certificado de Cumplimiento Fiscal
                  </p>
                </li>
                <li>
                  <p class="fac-a">
                    <span class="material-symbols-outlined fac-span">task_alt</span>
                    Certificado de Pre-inscripción como Proveedor
                  </p>
                </li>
                <li>
                  <p class="fac-a">
                    <span class="material-symbols-outlined fac-span">task_alt</span>
                    Emisión de Boletas de IPV
                  </p>
                </li>
                <li>
                  <p class="fac-a">
                    <span class="material-symbols-outlined fac-span">task_alt</span>
                    Carnet de vacunas
                  </p>
                </li>
                <li>
                  <p class="fac-a">
                    <span class="material-symbols-outlined fac-span">task_alt</span>
                    Recibo de sueldo (para agentes públicos)
                  </p>
                </li>
                <li>
                  <p class="fac-a">
                    <span class="material-symbols-outlined fac-span">task_alt</span>
                    Certificado Residual Banco San Juan
                  </p>
                </li>
                <li>
                  <p class="fac-a">
                    <span class="material-symbols-outlined fac-span">task_alt</span>
                    Registro Único de Proveedores del Estado
                  </p>
                </li>
                <li class="mb-2">
                  <p class="fac-a">
                    <span class="material-symbols-outlined fac-span">task_alt</span>

                    Pedir turnos online
                  </p>
                </li>
                <li class="mb-2">
                  <p class="fac-a">
                    <span class="material-symbols-outlined fac-span">task_alt</span>
                    Y otros
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Fin Seccion TRAMITES CIDI-->

  <!-- INICIO SECCION BANNERS-->
  <section>
    <div class="container">
      <h2 class="pb-3">Novedades</h2>
      <a href="https://billetera.sanjuan.gob.ar/">
        <img src="https://billetera.sanjuan.gob.ar/images/banner-bsj.jpg" class="img-fluid" />
      </a>
    </div>
  </section>
  <!-- FIN SECCION BANNERS-->

  <!-- INICIO SECCION DESCARGA-->
  <section class="bg-light">
    <div class="container">
      <div class="row">
        <div
          class="col-12 col-sm-6 d-flex text-center justify-content-center align-items-center order-last order-sm-first">
          <a href="https://mi.sanjuan.gob.ar/ ">
            <img class="img-fluid" src="./assets/img/app.png" />
          </a>
        </div>

        <div class="col-12 col-sm-6 ps-5 pt-4 order-first order-sm-last">
          <div class="col pb-4">
            <h3 class="text-ptn-color-gsj-2 pb-3">
              Descargar la App <br />
              <n class="fac-span text-xl-start">GOBIERNO DE SAN JUAN</n>
            </h3>
            <p class="texto-descargar">
              Descarga la app tus trámites en un solo lugar, de manera rápida,
              fácil y segura
            </p>

            <ul class="nav flex-column flex-md-row justify-content-left align-text-center">
              <!-- Boton Descarga Play Store-->
              <li>
                <a class="nav-link text-center d-flex align-items-center descargar-app-link"
                  href="https://play.google.com/store/apps/details?id=sanjuan.gob.ar.app">
                  <img src="./assets/img/playstore.svg" alt="Descargar en Play Store" height="30" width="30"
                    class="mx-1" />
                  Play Store
                </a>
              </li>
              <!-- Fin Boton Descarga Play Store-->

              <!-- Boton Descarga App Store-->
              <li>
                <a class="nav-link text-center d-flex align-items-center descargar-app-link"
                  href="https://apps.apple.com/ar/app/gobierno-de-san-juan/id1474019699">
                  <img src="./assets/img/applestore.svg" alt="Descargar en App Store" height="30" width="30"
                    class="mx-1" />
                  App Store
                </a>
              </li>
              <!-- Fin Boton Descarga App Store-->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- FIN SECCION DESCARGA-->

  <!-- INICIO ACCESOS RAPIDOS-->
  <BoxIconGridComponent titulo="Accesos Rápidos" :data="[
    {
      desc: null,
      icon: 'task_alt',
      target: '_self',
      title: 'Validación de comprobantes',
      url: 'https://validacion.sanjuan.gob.ar/',
    },
    {
      desc: null,
      icon: 'description',
      target: '_self',
      title: 'Decreto N° 1461',
      url: 'https://sanjuan.gob.ar/docs/hacienda/cidi/decreto1461ciudadanodigital.pdf',
    },
    {
      desc: null,
      icon: 'live_help',
      target: '_self',
      title: 'Preguntas Frecuentes',
      url: '?uid=CUMV-VIEW-DropdownComponent',
    },
    {
      desc: null,
      icon: 'question_answer',
      target: '_self',
      title: 'Consultas y Solicitudes',
      url: '?uid=CUMV-VIEW-FormComponent',
    },
  ]" />

  <GridLinkComponent :columnas="4" titulo="Links Útiles" :data="[
    {
      subtitle: 'Página web',
      target: '_blank',
      title: 'Ministerio de Economía, Hacienda y Finanzas',
      url: 'https://hacienda.sanjuan.gob.ar/',
    },
    {
      subtitle: 'Página web',
      target: '_blank',
      title: 'Secretaría de Transformación Digital y Modernización del Estado',
      url: 'https://sgp.sanjuan.gob.ar/',
    },
    {
      subtitle: 'Página web',
      target: '_blank',
      title: 'Dirección de Gobierno Digital',
      url: 'https://gobiernodigital.sanjuan.gob.ar/',
    },
    {
      subtitle: 'Página web',
      target: '_blank',
      title: 'Dirección de Ciberseguridad',
      url: 'https://ciberseguridad.sanjuan.gob.ar/',
    },
    {
      subtitle: 'Página web',
      target: '_blank',
      title:
        'Dirección de Recursos Humanos y Organización del Empleo Público',
      url: 'https://rrhh.sanjuan.gob.ar/',
    },
    {
      subtitle: 'Página web',
      target: '_blank',
      title: 'IIEE - Instituto de Investigaciones Económicas y Estadísticas',
      url: 'https://web.sanjuan.gob.ar/iiee/',
    },
    {
      subtitle: 'Página Web',
      target: '_blank',
      title: 'Dirección Provincial de Informática',
      url: 'https://dpi.sanjuan.gob.ar/',
    },
    {
      subtitle: 'Página web',
      target: '_blank',
      title: 'Innova',
      url: 'http://sj.sanjuan.gob.ar/',
    },

    {
      subtitle: 'Página web',
      target: '_blank',
      title: 'Compras Públicas',
      url: 'https://compraspublicas.sanjuan.gob.ar/',
    },
  ]" />
  <!-- FIN ACCESOS RAPIDOS-->

  <!-- CHAT BOT -->
<!--   <ChatBotComponent /> -->
  <!-- FIN CHAT BOT -->
</template>
