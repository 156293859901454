<template>
    <section class="secondary-color d-flex justify-content-center align-items-center mt-4">
      <footer class="d-flex flex-column flex-lg-row justify-content-between md-align-items-start align-items-center align-items-lg-center gap-3 py-4 px-3 w-100 container">
        <div>
          <img src="../../assets/iconos/Logo-San-Juan-Blanco.svg" alt="Logo San Juan">
        </div>
        <div class="d-flex gap-5">
          <img src="../../assets/iconos/Institucional.svg" alt="Logo institucional">
          <div class="d-flex flex-column align-items-start justify-content-center text-white font-weight-semibold">
            <div class="d-flex flex-column">
              <span class="h5 font-weight-normal">Centro Cívico</span>
              <span class="fw-light">
                Avenida Libertador General San Martín 750 Oeste | C.P: 5400 | San Juan | Argentina
                Conmutador: (0264) 459 2201
              </span>
            </div>
            <div class="d-flex flex-column">
              <span class="h5 fw-bold pt-2">Mesa de Atención Ciudadana</span>
              <span class="fw-light">
                Planta baja sector H3
              </span>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-center gap-3">
          <a href="">
            <img src="../../assets/iconos/Facebook.svg" alt="Logo Facebook">
          </a>
          <a href="">
            <img src="../../assets/iconos/Twitter.svg" alt="Logo Twitter">
          </a>
          <a href="">
            <img src="../../assets/iconos/Instagram.svg" alt="Logo Instagram">
          </a>
          <a href="">
            <img src="../../assets/iconos/Youtube.svg" alt="Logo Youtube">
          </a>
        </div>
      </footer>
    </section>
  </template>
  
  <style scoped>
  .bg-secondary {
    background-color: #333; /* Cambia esto por el color que desees */
  }
  .gap-3 {
    gap: 1rem; /* Ajusta el espacio entre elementos */
  }
  .font-weight-bold {
    font-weight: bold;
  }
  .font-weight-semibold {
    font-weight: 600;
  }
  </style>
  