<template>
  <section>
    <div class="container">
      <h2 class="pb-2">
        {{ titulo != null ? titulo : this.title != null ? this.title : "" }}
      </h2>

      <div
        class="row row-cols-1 g-4"
        v-bind:class="[
          columnas > 0 ? 'row-cols-md-' + columnas : 'row-cols-md-3',
        ]"
      >
        <div class="accordion row row-cols-1 g-4">
          <!-- Recorre los elementos para crear los acordeones -->
          <div class="col-6" v-for="(el, i) in items" :key="i">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="'#collapse' + i"
                  :aria-controls="'collapse' + i"
                  @click="toggleAccordion(i)"
                >
                  {{ el.title }}
                </button>
              </h2>
              <div
                :id="'collapse' + i"
                class="accordion-collapse collapse"
                :data-bs-parent="accordion"
              >
                <div class="accordion-body">
                  <p v-html="el.desc"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
const axios = require("axios");
export default {
  name: "DropdownComponent",
  props: {
    titulo: String,
    subtitulo: String,
    columnas: String,
    filtros: Object,
    data: Object,
  },
  data() {
    return {
      hash: "DROPC",
      items: null,
      title: null,
      desc: null,
    };
  },
  mounted() {
    if (this.data == null) {
      let params = {};
      if (this.filtros != null && this.filtros != "") {
        params = this.filtros;
      }
      params.tipo = this.hash;
      axios
        .post(process.env.VUE_APP_API_URL, params)
        .then((response) => {
          this.items = response.data ? response.data.items : null;
          this.title = response.data ? response.data.title : null;
          this.desc = response.data ? response.data.desc : null;
          this.items.forEach((item) => (item.active = false));
          localStorage.setItem(this.hash, JSON.stringify(response));
        })
        .catch((error) => {
          if (!error.response) {
            console.log("Fuera de Linea");
            if (localStorage.getItem(this.hash)) {
              console.log("Cargando elementos de cache para " + this.hash);
              var resp = JSON.parse(localStorage.getItem(this.hash));
              this.items = resp.data ? resp.data.items : null;
              this.title = resp.data ? resp.data.title : null;
              this.desc = resp.data ? resp.data.desc : null;
            }
          }
        });
    } else {
      this.items = this.data;
    }
  },
  methods: {
    toggleAccordion(index) {
      this.items.forEach((item, i) => {
        if (i === index) {
          item.active = !item.active; // Cambia el estado activo del elemento clicado
        } else {
          item.active = false; // Cierra los otros elementos
        }
      });
    },
  },
};
</script>
