<template>
  <div class="" style="min-height: 150px">
    <div
      style="text-align: justify; overflow-wrap: break-word"
      v-if="!customView && !isDropdownComponent && !isFormComponent"
    >
      <div v-html="html"></div>
    </div>
    <div class="dinamic" v-if="customView">
      <component v-bind:is="customView"></component>
    </div>
    <FormComponent v-if="isFormComponent" />
    <DropdownComponent
      v-if="isDropdownComponent"
      titulo="Preguntas Frecuentes"
      columnas="1"
      :data="[
        {
          desc: 'Es una Plataforma de AUTOGESTIÓN online con Acceso desde cualquier lugar, con INTERNET; el cual le permitirá realizar TRÁMITES y acceder a DOCUMENTACIÓN de personas específicas.<br>Esta DOCUMENTACIÓN puede ser presentada en ORGANISMOS públicos y privados, la LEGITIMIDAD de estas puede ser validada, a través de un código que poseen en la parte superior derecha.',
          title: '1. ¿Qué es Ciudadano Digital?',
        },
        {
          desc: 'Toda persona física mayor de 18 años de edad que cuente con un correo electrónico válido. Estamos trabajando para ampliar el alcance (menores de edad, personas jurídicas, etc.), siempre conforme al artículo 10 del decreto de Ciudadano Digital.',
          title: '2. ¿Quiénes Pueden Obtener Una Cuenta De Ciudadano Digital?',
        },
        {
          desc: 'Tener un teléfono celular con versión de Android o IOS compatible con la aplicación de “Gobierno de San Juan”, conexión a internet y DNI en mano.Y seguir el paso a paso que se indica en la aplicación de ciudadano digital.',
          title: '3. ¿Qué necesito para crear una cuenta en Ciudadano Digital?',
        },
        {
          desc: 'Es posible acceder a Ciudadano Digital a través de todo dispositivo móvil y también desde una PC o tablet. Descargando previamente la aplicación del Gobierno de San Juan.<br> Asimismo, se puede ingresar desde cualquier navegador web. Recordá que para ingresar desde tu navegador, antes debés crear la cuenta en la aplicación del Gobierno de San Juan.',
          title:
            '4. ¿Para qué Tipo De Dispositivos Está Disponible Ciudadano Digital?',
        },
        {
          desc: 'En caso de olvidar la contraseña, podrás volver a realizar la creación de cuenta desde una app móvil tantas veces lo requiera ya que la cuenta seguirá siendo una por cada DNI o puede ingresar a la web mi.sanjuan.gob.ar , seleccionar la opción “¿Olvidaste tu contraseña?”, y seguir los pasos indicados en la pantalla.<br>Si lo que deseas es simplemente cambiar tu contraseña, podés hacerlo ingresando en “Cambiar contraseña”, a través del menú “Más opciones”. Allí encontrarás, además, los requisitos mínimos que debe cumplir tu nueva contraseña.',
          title: '5. ¿Qué pasa si olvido mi Contraseña de Ciudadano Digital?',
        },
        {
          desc: `<h6>Para crear tu cuenta de Ciudadano Digital simplemente necesitas tu dispositivo móvil con cámara habilitada, tu DNI, un correo electrónico válido y tu número de celular. </h6>
            <ul class='list-unstyled mt-4'>
              <li class='style-none'>
                <p>
                  <img
                    src='https://sanjuan.gob.ar/img/cidi/portal/01.svg'
                    height='30'
                    width='30'
                    class='mx-1'
                  />
                  Descargue la aplicación Gobierno de San Juan en
                  <a
                    href='https://play.google.com/store/apps/details?id=sanjuan.gob.ar.app'
                    >Google Play Store</a
                  >
                  o
                  <a
                    href='https://apps.apple.com/ar/app/gobierno-de-san-juan/id1474019699'
                    >App Store.</a
                  >
                </p>
              </li>
              <li class='style-none'>
                <p>
                  <img
                    src='https://sanjuan.gob.ar/img/cidi/portal/02.svg'
                    height='30'
                    width='30'
                    class='mx-1'
                  />
                  Pulse <b>'Permitir'</b> (para que la app use la cámara) y
                  luego continúe pulsando los botones bordó.
                </p>
              </li>
              <li class='style-none'>
                <p>
                  <img
                    src='https://sanjuan.gob.ar/img/cidi/portal/03.svg'
                    height='30'
                    width='30'
                    class='mx-1'
                  />
                  Luego pulse:
                </p>
                <ul class='mb-3'>
                  <li>'Ingresá a Ciudadano Digital'</li>
                  <li class='li'>'Crear cuenta'</li>
                  <li class='li'>'Comenzar'</li>
                  <li class='li'>'Continuar'</li>
                </ul>
              </li>
              <li class='style-none'>
                <p>
                  <img
                    src='https://sanjuan.gob.ar/img/cidi/portal/04.svg'
                    height='30'
                    width='30'
                    class='mx-1'
                  />
                  Pulse <b>'Tomar Captura'</b> para sacarse una selfie.
                </p>
              </li>
              <li class='style-none'>
                <p>
                  <img
                    src='https://sanjuan.gob.ar/img/cidi/portal/05.svg'
                    height='30'
                    width='30'
                    class='mx-1'
                  />

                  Escanee el código <b>QR</b> que aparece en el <b>DNI.</b>
                </p>
              </li>
              <li class='style-none'>
                <p>
                  <img
                    src='https://sanjuan.gob.ar/img/cidi/portal/06.svg'
                    height='30'
                    width='30'
                    class='mx-1'
                  />

                  Coloque el número de Celular (sin 0 y sin 15) y pulse
                  <b>'Enviar código'.</b>
                </p>
              </li>
              <li class='style-none'>
                <p>
                  <img
                    src='https://sanjuan.gob.ar/img/cidi/portal/07.svg'
                    height='30'
                    width='30'
                    class='mx-1'
                  />

                  Reciba un mensaje de texto desde el número 40140 con el CÓDIGO
                  POR SMS.
                </p>
                <ul class='mb-3'>
                  <li>
                    El código SMS debe ingresar en la app antes de finalizar los
                    2 minutos. Luego de ese tiempo debe pulsar
                    <b> 'Reenviar código'.</b>
                  </li>
                  <li>Pulse <b>'Continuar'</b></li>
                </ul>
              </li>
              <li class='style-none'>
                <p>
                  <img
                    src='https://sanjuan.gob.ar/img/cidi/portal/08.svg'
                    height='30'
                    width='30'
                    class='mx-1'
                  />

                  Escriba el correo en el que desea recibir la información
                  personal.
                </p>
              </li>
              <li class='style-none'>
                <p>
                  <img
                    src='https://sanjuan.gob.ar/img/cidi/portal/09.svg'
                    height='30'
                    width='30'
                    class='mx-1'
                  />

                  Reciba un correo desde
                  <b>ciudadanodigital@sanjuan.gov.ar </b> con el CÓDIGO POR
                  MAIL.
                </p>
                <ul class='mb-3'>
                  <li>
                    El código MAIL se debe ingresar en la app antes de finalizar
                    los 2 minutos. Luego de ese tiempo debe pulsar
                    <b>'Reenviar código'.</b>
                  </li>
                  <li>Pulse 'Continuar'.</li>
                </ul>
              </li>
              <li class='style-none'>
                <p>
                  <img
                    src='https://sanjuan.gob.ar/img/cidi/portal/10.svg'
                    height='30'
                    width='30'
                    class='mx-1'
                  />

                  Revise que los datos personales sean correctos; tildar la
                  confirmación de declaración jurada.
                </p>
                <ul class='mb-3'>
                  <li>Pulse 'Continuar'.</li>
                </ul>
              </li>
              <li class='style-none'>
                <p>
                  <img
src='https://sanjuan.gob.ar/img/cidi/portal/11.svg'
                    height='30'
                    width='30'
                    class='mx-1'
                  />
                  Ingrese la <b>CONTRASEÑA</b> con la que quiere abrir su
                  ciudadano digital; cumplir con las condiciones que aparecen en
                  pantalla ( 1 Mayúscula; 1 número; etc). Cada condición
                  cumplida se pondrá verde.
                </p>
                <ul class='mb-3'>
                  <li>Pulse 'Completar registro'.</li>
                </ul>
              </li>
              <li class='style-none'>
                <p>
                  <img
                    src='https://sanjuan.gob.ar/img/cidi/portal/12.svg'
                    height='30'
                    width='30'
                    class='mx-1'
                  />
                  ¡LISTO! Ya podrás ingresar a la App pulsando 'Iniciar sesión'
                  o a través de la Web <a href='https://mi.sanjuan.gob.ar/'>https://mi.sanjuan.gob.ar/</a>
                </p>
                <ul class='mb-3'>
                  <li>
                    Para abrir su cuenta debe ingresar siempre:
                    <b>DNI, Clave/Contraseña y Género.</b>
                  </li>
                </ul>
              </li>
            </ul>`,
          title: '6. ¿Cuál es el paso a paso para Crear la cuenta?',
        },
        {
          desc: `<p> Todo documento generado en ciudadano digital posee validez legal según decreto 1461-MHF-2019. Art. 3°.”...con plena validez jurídica y probatoria para su utilización en trámites, actuaciones y procedimientos de la Administración Pública Provincial” Art.86 Ley N° 2.188-I Quedan excluidos de tributar cualquier tasa retributiva de servicios de documentos digitales que se obtengan en el marco del Decreto N° 1461-MHF-2019. <br> La Veracidad de los mismos se puede comprobar ingresando los datos en <a href='https://validacion.sanjuan.gob.ar/'>https://validacion.sanjuan.gob.ar/ </a>(que también está disponible en el home de la aplicación móvil), ingresando el código que figura en la documentación emitida y/o los demás datos solicitados.</p>`,
          title:
            '7. ¿Los documentos emitidos en Ciudadano Digital tienen validez legal?',
        },
        {
          desc: ` <h5 class='text-ptn-color-gsj-brand pt-3'>Recibos de Sueldo:</h5>
  <p>Se ponen a disposición los recibos de sueldo digitales a todo empleado de Educación Privada  y de los poderes Ejecutivo y Legislativo Provincial. Dicha aplicación deja visible desde el día 10 de cada mes, el recibo correspondiente del mes finalizado.</p>

  <h5 class='text-ptn-color-gsj-brand'>Partidas de Nacimiento:</h5>
  <p>Se muestran los datos personales que aparecen en el Registro Civil, el cual debe tener la partida de nacimiento digitalizada. Las partidas de Nacimiento que se podrán ver son tanto del DNI del consultante como de su madre/padre e hijos vinculados que sean nacidos en la provincia.</p>

  <h5  class='text-ptn-color-gsj-brand'>Carnet de vacunas:</h5>
  <p>Se muestran los datos personales que aparecen en SISA (Sistema Integrado de información Sanitaria Argentina) el mismo contiene datos de los centros vacunatorios del país.</p>

  <h5 class='text-ptn-color-gsj-brand'>Certificado de Activos:</h5>
  <p>Se genera el certificado del DNI del consultante con los datos personales que aparecen en SIPE (Sistema Integrado de personas) el mismo contiene un padrón único de personas humanas y jurídicas.</p>

  <h5 class='text-ptn-color-gsj-brand'>Certificado de Cumplimiento Fiscal:</h5>
  <p>Se genera el certificado del DNI del consultante con los datos personales que aparecen en AFIP (Administración Federal de Ingresos Públicos) el mismo contiene datos de la recaudación de impuestos autárquico del Estado argentino.</p>

  <h5 class='text-ptn-color-gsj-brand'>Certificado de Antecedentes:</h5>
  <p>Se genera el certificado del DNI del consultante con los datos personales que aparecen en la Policía de San Juan, el mismo contiene datos de los antecedentes penales y pedidos de captura de las personas.</p>

  <h5 class='text-ptn-color-gsj-brand'>Impuesto Inmobiliario y Automotor:</h5>
  <p>Se genera información de las boletas a vencer del DNI del consultante con los datos personales que aparecen en Rentas.</p>

  <h5 class='text-ptn-color-gsj-brand'>Certificado de Libre deuda de IPV:</h5>
  <p>Se genera el certificado del DNI del consultante con los datos personales que aparecen en el IPV. Al emitirse se verifica la deuda del solicitante en el Sistema Integrado del Instituto Provincial de la Vivienda. En caso de poseer deuda debe presentarse en las oficinas de Gestión de Cobranza de IPV para tomar conocimiento sobre su estado de deuda.</p>

  <h5 class='text-ptn-color-gsj-brand'>Certificado de Avalúo Catastral:</h5>
  <p>Se genera el certificado del DNI del consultante con los datos personales que aparecen en la Dirección de Geodesia y Catastro, según las nomenclaturas disponibles.</p>
<h5 class='text-ptn-color-gsj-brand'>Estado de Préstamos - CAS:</h5>
  <p>Se genera información de los préstamos personales del DNI del consultante con los datos y estados de las cuotas a vencer.</p>
<h5 class='text-ptn-color-gsj-brand'>Consulta de Pagos y retenciones:</h5>
  <p>Se genera información de los pagos y retenciones qué tiene el usuario tomando como periodo por defecto un año.</p>
  <h5 class='text-ptn-color-gsj-brand'>Actas por infracción a la Ley de Tránsito:</h5>
  <p>Se genera información de las boletas a vencer del DNI del consultante con los datos personales que poseen los Juzgados de Faltas. En la información resultante se visualiza el estado de las boletas (pagas o impagas).</p>
`,
          title: '8. Servicios Vigentes',
        },
      ]"
    />
    <component v-bind:is="this.customView"></component>
  </div>
  <div class="text-center pt-4">
    <p>
      <button v-on:click="back" class="btn btn-secondary text-uppercase">
        Volver
      </button>
    </p>
  </div>
</template>

<script>
const axios = require("axios");
import DropdownComponent from "./components/resources/DropdownComponent.vue";
import FormComponent from "./components/resources/FormComponent.vue";
export default {
  name: "InfoView",
  props: {
    uid: String,
    filtros: Object,
  },
  data() {
    return {
      html: null,
      customView: null,
      isDropdownComponent: false,
      isFormComponent: false,
    };
  },
  methods: {
    back: function () {
      history.back();
    },
  },

  mounted() {
    if (this.uid === "CUMV-VIEW-FormComponent") {
      this.isFormComponent = true;
    } else if (this.uid === "CUMV-VIEW-DropdownComponent") {
      this.isDropdownComponent = true;
    } else if (this.uid.includes("CUMV-"))
      switch (this.uid) {
        case "CUMV-que-es": {
          this.html =
            "<section><div class='container'><div class='row d-flex align-items-center text-start'><h2 class='pb-5'>¿Qué es y para qué sirve Ciudadano Digital?</h2><div class='col-12 col-sm-4 d-flex text-center justify-content-center align-items-center'><img class='img-fluid' src='img/app.cc110ed8.png'></div><div class='col-12 col-sm-8 pl-2 pt-4'><div class='row'><div class='pb-4 col-md-auto justify-content-center'><div class='card'><div class='card-body rounded d-flex align-items-center justify-content-center'><div><p class='card-text'> En el marco del Plan de Modernización del Estado, el Gobierno de la Provincia de San Juan presenta Ciudadano Digital. Se trata de una plataforma de autogestión online que permite a los ciudadanos acceder a documentación, trámites y consultas personales de forma simple y segura.<br><br> Ciudadano Digital es un canal online alternativo frente a la tradicional modalidad presencial de gestión que permite optimizar tiempos y recursos. A través de una única cuenta personal, los ciudadanos podrán realizar distintos trámites y servicios digitales que brinda el Gobierno de la Provincia de San Juan.<br> <br>Podrás obtener tu cuenta de Ciudadano Digital descargando la aplicación Gobierno de San Juan en App Store o Google Play Store, desde cualquier dispositivo móvil. </p></div></div></div></div></div></div></div></div></section>";
          break;
        }
        case "CUMV-tutorial": {
          this.html = ` <!-- Seccion TUTORIAL CIDI -->
  <section>
    <div class="container">
      <div class="row ">
        <!-- VIDEO TUTORIAL-->
        <h2 class="pb-2">¿Cómo creo mi cuenta?</h2>
        
        <video class="video_crear_cuenta" src="https://cidi.sanjuan.gob.ar/video/CrearCuentaCIDI.mp4" controls poster="posterimage.jpg">
          Tu navegador no admite el elemento <code>video</code>.
        </video>

        <!-- FIN VIDEO TUTORIAL-->
        <!-- LISTADO TUTORIAL-->
        <div class="card">
          <div class="card-body rounded">
            <div>
              <h3 class="fac-span text-start mt-3">
                Tutorial Paso a Paso
              </h3>
            </div>
          </div>
          <div class="card-body text-start">
            <h5>
              Para crear tu cuenta de Ciudadano Digital simplemente necesitas tu
              dispositivo móvil con cámara habilitada, tu DNI, un correo
              electrónico válido y tu número de celular.
            </h5>
            <ul class="list-unstyled mt-4">
              <li>1.	Descarga la aplicación "Gobierno de San Juan" desde Google Play Store o App Store.</li>
              <li>2.	Abre la aplicación y selecciona "Ingresá a Ciudadano Digital".</li>
              <li>3.	Elige "Crear cuenta".Debe permitir que la aplicación tome fotos y grabe videos.</li>
              <li>4.	Continúa, pulsando el símbolo “>” que se encuentra en la parte superior derecha de la pantalla.</li>
              <li>5.	En la pantalla ¡Tomaremos una fotografía!,tener en cuenta que el rosto se encuentre descubierto, sin anteojos y con buena iluminación. Poner el celular a la altura de los ojos. Pulsar el símbolo siguiente “>”.</li>
              <li>6.	Ubicar el rostro dentro del visor de la cámara, y pulsar el ícono “cámara” ubicado en la parte inferior derecha de la pantalla. Luego de tomar la foto, continuar con siguiente “>” o volver a tomar.</li>
              <li>7.	Pulsa el botón “Escanear código de barras”.</li>
              <li>8.	Colocar el DNIfrente a la cámara enfocando el código QR. Pulsar “Siguiente”.</li>
              <li>9.	Ingresar el número de celular (sin 0 ni 15) y presionar "Enviar código".</li>
              <li>10.	Recibirá un mensaje de texto con el Código por SMS, desde el número 40140. Introduce este código en la app antes de que pasen 2 minutos. Si el tiempo expira, selecciona "Reenviar código".</li>
              <li>11.	Ingresar el correo electrónico donde desea recibir información personal.</li>
              <li>12.	Recibirá un correo desde ciudadanodigital@sanjuan.gov.ar con el CÓDIGO POR MAIL. Introducir este código en la app antes de que pasen 2 minutos. Si el tiempo expira, seleccionar "Reenviar código".</li>
              <li>13.	Verificar que los datos personales sean correctos y marcar la confirmación de declaración jurada (si no observa la casilla, subir la pantalla).</li>
              <li>14.	Pulsar el símbolo siguiente “>”.</li>
              <li>15.	Ingresar la contraseña que va a utilizar para la cuenta del Ciudadano Digital, cumpliendo con las condiciones que aparecen en pantalla (al menos 1 mayúscula, 1 número, etc.). Cada condición cumplida se marcará en verde.</li>
              <li>16.	Pulsar "Completar registro" (si no se visualiza el botón, subir la pantalla).</li>
              <li>17.	¡LISTO! Ahora puedes acceder a la aplicación pulsando "Iniciar Sesión" o a través del sitio web <a href="https://cidi.sanjuan.gob.ar/">https://cidi.sanjuan.gob.ar/</a> en "Ingresar a mi cuenta".</li>
            </ul>
          </div>
        </div>
        <!-- FIN LISTADO TUTORIAL-->
      </div>
    </div>
  </section>
  <!-- Fin Seccion TUTORIAL CIDI-->
`;
        }
      }
    else {
      let params = {};
      if (this.filtros != null && this.filtros != "") {
        params = this.filtros;
      }
      params.uid = this.uid;

      axios
        .post(process.env.VUE_APP_API_URL, params)
        .then((response) => {
          this.html = response.data ? response.data.html : null;
          localStorage.setItem(this.uid, JSON.stringify(response));
        })
        .catch((error) => {
          if (!error.response) {
            console.log("Fuera de Linea");
            if (localStorage.getItem(this.uid)) {
              console.log("Cargando elementos de cache para " + this.uid);
              var resp = JSON.parse(localStorage.getItem(this.uid));
              this.html = resp.data ? resp.data.html : null;
            }
          }
        });
    }
  },
  components: { DropdownComponent, FormComponent },
};
</script>
