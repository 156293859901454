<template>
  <div id="botpress-webchat"></div>
</template>

<script>
export default {
  mounted() {
    const script = document.createElement("script");
    script.src =
      "https://bot.sanjuan.gob.ar/assets/modules/channel-web/inject.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      window.botpressWebChat.init({
        enableReset: true,
        host: "https://bot.sanjuan.gob.ar",
        botId: "cidi",
      });
    };
  },
};
</script>
