<template>
  <section>
    <div class="container">
      <h2 class="pb-4">
        {{ titulo != null ? titulo : this.title != null ? this.title : "" }}
      </h2>
      <vueper-slides
        v-if="items"
        class="img-fluid no-shadow"
        :visible-slides="elementos != null ? elementos : 3"
        :bullets="mostrarIndicadores"
        :gap="3"
        :slide-ratio="this.aspectRadio"
        :breakpoints="this.breakpoints"
        :dragging-distance="50"
        :infinite="items.length > 1 && items.length > this.elementos"
        :arrows="this.mostrarFlechas"
        v-bind:class="[
          this.forma == 'circular' ? 'circular' : '',
          !mostrarImagen ? 'noimage' : '',
        ]"
        slide-multiple
        autoplay
        :touchable="true"
      >
        <vueper-slide
          v-for="(slide, i) in items"
          :key="i"
          :image="slide.imagen && this.mostrarImagen ? slide.imagen : null"
          v-bind:item="slide"
          :link="slide.url ? slide.url : null"
          :openInNew="slide.target"
          aria-hidden="false"
          v-bind:class="[
            this.ajustar ? 'vueperslideExpand' : '',
            this.items.length <= this.elementos || this.mostrarBordes
              ? 'multi-border'
              : '',
          ]"
          :aria-label="
            slide.title
              ? slide.title
              : slide.desc
              ? slide.desc
              : 'Click para saber más'
          "
        >
          <template #content>
            <div class="vueperslide-link">
              <div class="vueperslide__content-wrapper text-white p-3">
                <span
                  class="card-img-top img-fluid"
                  v-bind:class="[
                    !mostrarImagen ? 'link-secondary' : 'text-white',
                  ]"
                  v-if="this.mostrarIcono"
                >
                  <svg
                    class=""
                    v-bind:class="[!mostrarTitulo ? 'ptn-i-4x' : 'ptn-i-3x']"
                  >
                    <use v-bind="{ 'xlink:href': '#' + slide.icon }"></use>
                  </svg>
                </span>
                <div
                  class="px-2 py-2 fs-5 h4"
                  style="border-radius: 6px"
                  v-bind:class="[
                    !mostrarImagen
                      ? 'link-secondary'
                      : 'text-bg-ptn-color-gsj-2',
                  ]"
                  v-if="slide.title && this.mostrarTitulo"
                >
                  {{ slide.title }}
                </div>
                <div v-if="this.mostrarDescripcion">
                  {{ slide.desc }}
                </div>
              </div>
            </div>
          </template>
        </vueper-slide>
      </vueper-slides>
    </div>
  </section>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
const axios = require("axios");
export default {
  name: "MultiSliderComponent",
  components: { VueperSlides, VueperSlide },
  props: {
    titulo: String,
    subtitulo: String,
    desccripcion: String,
    filtros: Object,
    data: Object,
    elementos: Number,
    ajustar: Boolean,
    forma: {
      type: String,
      validator(value) {
        return ["cuadrada", "circular", "rectangular"].includes(value);
      },
    },
    mostrarIcono: {
      type: Boolean,
      default: true,
    },
    mostrarDescripcion: {
      type: Boolean,
      default: true,
    },
    mostrarTitulo: {
      type: Boolean,
      default: true,
    },
    mostrarImagen: {
      type: Boolean,
      default: true,
    },
    mostrarIndicadores: {
      type: Boolean,
      default: true,
    },
    mostrarFlechas: {
      type: Boolean,
      default: true,
    },
    mostrarBordes: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hash: "MSC",
      items: null,
      title: null,
      desc: null,
      aspectRadio: 1 / 6,
      breakpoints: {
        600: {
          visibleSlides: 1,
          slideMultiple: 1,
          slideRatio: 1,
          bulletsOutside: true,
        },
      },
    };
  },
  created() {
    if (this.forma) {
      switch (this.forma) {
        case "cuadrada": {
          this.aspectRadio = 1 / 4;
          if (this.elementos) {
            this.aspectRadio = 0.8 / this.elementos;
          }
          break;
        }
        case "circular": {
          this.aspectRadio = 0.9 / 3;
          if (this.elementos) {
            this.aspectRadio = 0.8 / this.elementos;
          }
          break;
        }
        case "rectangular": {
          this.aspectRadio = 1 / 3;
          this.breakpoints = {
            600: {
              visibleSlides: 1,
              slideMultiple: 1,
              slideRatio: 1.5,
            },
          };
          break;
        }
      }
    }
  },
  mounted() {
    if (this.data == null) {
      let params = {};
      if (this.filtros != null && this.filtros != "") {
        params = this.filtros;
      }
      params.tipo = this.hash;
      axios
        .post(process.env.VUE_APP_API_URL, params)
        .then((response) => {
          this.items = response.data ? response.data.items : null;
          this.title = response.data ? response.data.title : null;
          this.desc = response.data ? response.data.desc : null;
          localStorage.setItem(this.hash, JSON.stringify(response));
        })
        .catch((error) => {
          if (!error.response) {
            console.log("Fuera de Linea");
            if (localStorage.getItem(this.hash)) {
              console.log("Cargando elementos de cache para " + this.hash);
              var resp = JSON.parse(localStorage.getItem(this.hash));
              this.items = resp.data ? resp.data.items : null;
              this.title = resp.data ? resp.data.title : null;
              this.desc = resp.data ? resp.data.desc : null;
            }
          }
        });
    } else {
      this.items = this.data;
    }
  },
  methods: {
    getItem: function (index) {
      if (this.items) {
        return this.items[index];
      }
    },
  },
};
</script>
