<template>
    <section class="bg-light">
        <div class="container">
            <h2 class="pb-2">{{ titulo != null ? titulo : this.title != null ? this.title : '' }}</h2>
            <div class="row row-cols-1 row-cols-md-3 g-3">

                <div class="col mb-2" v-for="(el, index) in items" :key="index">
                    <a class="d-block pe-lg-4 lh-sm" v-bind:href="el.url" :target=el.target>
                        <h3 class="mb-0 fs-5">{{ el.title }}</h3>
                        <small class="text-muted">
                            <span v-if="el.url && el.url.includes('tel:')"
                                class="material-symbols-outlined gld-span">call</span>
                            <span v-if="el.url && el.url.includes('mailto:')"
                                class="material-symbols-outlined fac-span">mail</span>
                            {{ el.subtitle }}
                        </small>
                    </a>
                </div>


            </div>
        </div>
    </section>
</template>


<script>
const axios = require("axios");
export default {
    name: "GridLinkComponent",
    props: {
        titulo: String,
        filtros: Object,
        data: Object
    },
    data() {
        return {
            hash: "GLC",
            items: null,
            title: null,
            desc: null
        };
    },
    mounted() {
        if (this.data == null) {
            let params = {};
            if (this.filtros != null && this.filtros != "") {
                params = this.filtros;
            }
            params.tipo = this.hash;
            axios.post(process.env.VUE_APP_API_URL, params)
                .then(response => {
                    this.items = response.data ? response.data.items : null
                    this.title = response.data ? response.data.title : null
                    this.desc = response.data ? response.data.desc : null
                    localStorage.setItem(this.hash, JSON.stringify(response));
                })
                .catch(error => {
                    if (!error.response) {
                        console.log("Fuera de Linea");
                        if (localStorage.getItem(this.hash)) {
                            console.log("Cargando elementos de cache para " + this.hash);
                            var resp = JSON.parse(localStorage.getItem(this.hash));
                            this.items = resp.data ? resp.data.items : null
                            this.title = resp.data ? resp.data.title : null
                            this.desc = resp.data ? resp.data.desc : null
                        }
                    }
                })
        } else {
            this.items = this.data;
        }
    },
};
</script>

